<template>
  <div class="app-card">
    <div>
      <a-row type="flex" justify="space-between">
        <a-col :span="8">
          <a-tree
            @expand="onExpand"
            @select="onSelectedTree"
            :expandedKeys="expandedKeys"
            :autoExpandParent="autoExpandParent"
            :treeData="gData"
          >
            <template #row="data">
              <span style="margin-right:10px;">
                {{data.title}}
                <a-divider type="vertical" />
                <a-tag style="background: #fff; borderStyle: dashed;" v-if="data.data.BaseInfo && data.data.BaseInfo.ChargeName">负责人：{{data.data.BaseInfo.ChargeName}}</a-tag>
              </span>
              <a-button shape="circle" icon="edit" size="small" @click="editOrganization(data)" v-if="!($root.$data.privateState.role === 'Admin' && data.data.Uuid === $root.$data.privateState.userInfo.organization_uuid)" />
              <a-button style="margin-left:10px;" shape="circle" icon="plus" size="small" @click="addOrganization(data)" />
              <a-popconfirm
                v-if="data.data.Uuid !== rootUuid"
                title="是否确定删除该组织?"
                @confirm="confirmDeleted(data)"
                okText="确认"
                cancelText="取消"
              >
                <a-button style="margin-left:10px;" shape="circle" icon="minus" size="small" />
              </a-popconfirm>
            </template>
          </a-tree>
        </a-col>
        <a-col :span="8" v-if="cardShowData.title">
          <a-card :title="cardShowData.title">
            <a-descriptions title="" :column="1">
              <a-descriptions-item label="负责人姓名">
                {{cardShowData.BaseInfo ? cardShowData.BaseInfo.ChargeName : ''}}
              </a-descriptions-item>
              <a-descriptions-item label="负责人联系方式">
                {{cardShowData.BaseInfo ? cardShowData.BaseInfo.ChargeContact : ''}}
              </a-descriptions-item>
              <a-descriptions-item label="提点">
                {{cardShowData.BaseInfo ? cardShowData.BaseInfo.CutPercent : ''}}
              </a-descriptions-item>
              <a-descriptions-item label="利润分配比例">
                {{cardShowData.BaseInfo ? cardShowData.BaseInfo.ProfitMargin : ''}}
              </a-descriptions-item>
            </a-descriptions>
          </a-card>
        </a-col>
      </a-row>
    </div>
    <a-modal width="60%" :title="createAndEditModalTitle" v-model="createAndEditModalShow" @ok="createAndEditModalOk">
      <a-form-model ref="ruleForm" :model="form" :rules="formRules" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-form-model-item label="机构名称" prop="Name">
          <a-input v-model="form.Name" />
        </a-form-model-item>
        <a-form-model-item label="负责人姓名">
          <a-input v-model="form.BaseInfo.ChargeName" />
        </a-form-model-item>
        <a-form-model-item label="负责人联系方式">
          <a-input v-model="form.BaseInfo.ChargeContact" />
        </a-form-model-item>
        <a-form-model-item label="提点">
          <a-input-number style="width:100%" :formatter="value => `${value}%`" :parser="value => value.replace('%', '')" v-model="form.BaseInfo.CutPercent" :min="0" :max="100" />
        </a-form-model-item>
        <a-form-model-item label="利润分配比例">
          <a-input-number style="width:100%" :formatter="value => `${value}%`" :parser="value => value.replace('%', '')" v-model="form.BaseInfo.ProfitMargin" :min="0" :max="100" />
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import licia from 'licia'
import { Organization } from '@/api'
import { aliveVM } from '@/utils/mixin'

export default {
  name: 'SystemOrganization',
  mixins: [aliveVM],
  created () {
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.getTree()
    })
  },
  data () {
    return {
      labelCol: { span: 6 },
      wrapperCol: { span: 12 },
      hasStaff: true,
      rootUuid: '',
      createAndEditModalTitle: '新建机构',
      createAndEditModalShow: false,
      createAndEditModalForm: this.$form.createForm(this),
      nowFormType: '',
      cardShowData: {
        title: '',
        BaseInfo: {
          ChargeName: '',
          ChargeContact: ''
        }
      },
      nowEditData: null,
      form: {
        Name: '',
        BaseInfo: {
          ChargeName: '',
          ChargeContact: '',
          CutPercent: null,
          ProfitMargin: null
        }
      },
      formRules: {
        Name: [
          { required: true, message: '请填写名称', trigger: 'blur' },
          { min: 3, message: '名称长度至少两个字符', trigger: 'blur' },
        ],
      },
      expandedKeys: [],
      autoExpandParent: true,
      searchValue: '',
      gData: []
    }
  },
  methods: {
    async getTree () {
      const apiRes = await this.$axios.get(`${Organization}/GetTree`)
        .catch(e => {
          return {
            status: false,
            message: e.message.indexOf('401') > -1 ? '登录超时' : e.message
          }
        })
      if (!apiRes.status) {
        this.$message.error(apiRes.message)
        return
      }
      this.$set(this, 'gData', apiRes.data)
      this.$set(this, 'rootUuid', apiRes.data[0] ? apiRes.data[0].key : '')
    },
    onExpand(expandedKeys) {
      this.expandedKeys = expandedKeys;
      this.autoExpandParent = false;
    },
    onSelectedTree(_, info) {
      let cardShowData = {
        title: '',
        BaseInfo: {
          ChargeName: '',
          ChargeContact: '',
          CutPercent: null,
          ProfitMargin: null
        }
      }
      if (licia.isEmpty(info.selectedNodes[0])) {
        this.$set(this, 'cardShowData', cardShowData)
        return
      }
      const item = info.selectedNodes[0].data.props.data
      cardShowData = {
        title: item.Name,
        BaseInfo: {
          ChargeName: item.BaseInfo ? item.BaseInfo.ChargeName : '',
          ChargeContact: item.BaseInfo ? item.BaseInfo.ChargeContact : '',
          CutPercent: item.BaseInfo ? item.BaseInfo.CutPercent : null,
          ProfitMargin: item.BaseInfo ? item.BaseInfo.ProfitMargin : null,
        }
      }
      this.$set(this, 'cardShowData', cardShowData)
    },
    // createAndEditModal
    createAndEditModalOk () {
      this.$refs.ruleForm.validate(async valid => {
        if (!valid) {
          this.$message.error('请检查填写内容');
          return
        }
        const postData = JSON.parse(JSON.stringify(this.form))
        let url = ''
        if (this.nowFormType === 'edit') {
          url = `${Organization}/Edit?uuid=${this.nowEditData.key}`
        } else {
          url = `${Organization}/Create?uuid=${this.nowEditData.key}`
        }
        const apiRes = await this.$axios.post(url, postData)
          .catch(e => {
            return {
              status: false,
              message: e.message.indexOf('401') > -1 ? '登录超时' : e.message
            }
          })
        this.createAndEditModalShow = false
        if (!apiRes.status) {
          this.$message.error(apiRes.message)
          return
        }
        if (this.nowFormType === 'edit') {
          this.getTree()
        } else {
          this.nowEditData.children.push({
            title: apiRes.data.name,
            key: apiRes.data.uuid,
            data: {
              BaseInfo: apiRes.data.baseInfo,
            },
            scopedSlots: { title: 'row' },
            children: []
          })
          if (!this.expandedKeys.includes(this.nowEditData.key)) {
            this.expandedKeys.push(this.nowEditData.key)
          }
        }
        
        this.$message.success('操作成功')
      })
    },
    editOrganization (data) {
      this.nowEditData = data
      const form = {
        Name: data.title,
        BaseInfo: {
          ChargeName: data.data.BaseInfo ? data.data.BaseInfo.ChargeName : '',
          ChargeContact: data.data.BaseInfo ? data.data.BaseInfo.ChargeContact : '',
          CutPercent: data.data.BaseInfo ? data.data.BaseInfo.CutPercent : null,
          ProfitMargin: data.data.BaseInfo ? data.data.BaseInfo.ProfitMargin : null,
        }
      }
      this.$set(this, 'form', form)
      this.$set(this, 'nowFormType', 'edit')
      this.createAndEditModalShow = true
    },
    addOrganization (data) {
      this.nowEditData = data
      const form = {
        Name: '',
        BaseInfo: {
          ChargeName: '',
          ChargeContact: '',
          CutPercent: null,
          ProfitMargin: null
        }
      }
      this.$set(this, 'form', form)
      this.$set(this, 'nowFormType', 'create')
      this.createAndEditModalShow = true
    },
    async confirmDeleted (data) {
      // eslint-disable-next-line no-console
      const apiRes = await this.$axios.delete(`${Organization}/Delete?uuid=${data.key}`)
        .catch(e => {
          return {
            status: false,
            message: e.message.indexOf('401') > -1 ? '登录超时' : e.message
          }
        })
      if (!apiRes.status) {
        this.$message.error(apiRes.message)
        return
      }
      this.getTree()
    }
  }
}
</script>

<style>

</style>